<template>
  <div class="columns is-centered ml-5 mr-5 mb-4">
    <div class="column is-half">
      <div class="notification is-success is-light" v-if="licenseRequested">
        You have requested a license to try Ocelus, please check your mailbox <strong>{{ email }}</strong>.
      </div>
      <div class="notification is-warning is-light" v-if="warning">
        <button class="delete" v-on:click="warning = null"></button>
        {{ warningMessage }}
      </div>
      <div class="notification is-danger is-light" v-if="error">
        <button class="delete" v-on:click="error = null"></button>
        An error occurred.
        <br>
        {{ errorMessage }}
      </div>
      <picture-input
        ref="pictureInput"
        width="600"
        height="600"
        :crop="false"
        margin="4"
        accept="image/jpeg,image/png"
        size="10"
        :hide-change-button="true"
        :removable="true"
        remove-button-class="button"
        :custom-strings="{
          upload: '<p>Your device does not support file uploading.</p>',
          drag: 'Drag an image file <br>or click here to select one',
          remove: 'Remove image'
        }"
        @remove="resetData"
        @click="resetData"
        @change="setImage"
        :hidden="processing">
      </picture-input>
      <div class="sticky-image" v-if="processing && svgViewBox">
        <svg
          width="100%"
          height="100%"
          :viewBox="svgViewBox"
          xmlns="http://www.w3.org/2000/svg">
          <image :href="image" width="100%" height="100%" preserveAspectRatio/>
          <polygon
            v-for="(polygon, index) in polygons"
            :key="index"
            :points="polygonPoints(polygon)"
            :stroke="index == selectedResult ? 'red' : 'blue'"
            stroke-opacity="0.4"
            :fill="index == selectedResult ? 'red' : 'blue'"
            fill-opacity="0.2"
            class="is-clickable"
            v-on:click="selectedResult = index"
          />
        </svg>
        <button v-if="!loading" class="button is-danger mt-4" v-on:click="retry">Change image</button>
      </div>
    </div>
    <div class="column is-half">

      <form class="mb-4" v-on:submit.prevent="generateResults">
        <div class="field is-horizontal mb-1">
          <div class="field-label is-normal"><label class="label">Select the engine</label></div>
          <div class="field-body">
            <div class="control">
              <div class="field">
                <div class="select">
                  <select v-model="languageCode">
                    <option value="atr" v-if="enableATR">Generic Automatic Text Recognition (multilingual)</option>
                    <option value="gfr" v-if="enableATR">Generic Form Recognizer</option>
                    <option value="gtr-json" v-if="enableATR">Generic Table Recognizer - Display</option>
                    <option value="gtr-xlsx" v-if="enableATR">Generic Table Recognizer - Excel file</option>
                    <option value="fra" v-if="languages.includes('fra')">French Automatic Text Recognition (full page)</option>
                    <option value="eng" v-if="languages.includes('eng')">English Automatic Text Recognition (full page)</option>
                    <option value="arb" v-if="languages.includes('arb')">Arabic Automatic Text Recognition (full page)</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal mb-1">
          <div class="field-label is-normal"><label class="label">Enter your email address</label></div>
          <div class="field-body">
            <div class="control">
              <div class="field has-addons" v-if="licenseKey">
                <div class="control">
                  <input v-model="email" disabled class="input" type="email" required maxlength="150" size="32" placeholder="Contact email…" />
                </div>
                <div class="control">
                  <button type="button" class="button is-info is-light" v-on:click="resetLicense">
                    Reset
                  </button>
                </div>
              </div>
              <div class="field" v-else>
                <input v-model="email" class="input" type="email" required maxlength="150" size="32" placeholder="Contact email…" />
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label"><label class="label"></label></div>
          <div class="field-body has-text-left">
            <div class="control">
              <div class="field">
                <input type="checkbox" required/>
                By submitting an image to the Ocelus service, I accept the
                <TermsOfService />
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label"><label class="label"></label></div>
          <div class="field-body">
            <div class="control">
              <div class="field">
                <button type="submit" :disabled="loading" class="button is-info">Process document</button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <hr />

      <template v-if="!processing && !hasResults">
        <div class="message mx-6">
          <div v-if="TRANSCRIPTION_LANGUAGE_CODES.includes(languageCode)" class="message-body">
            Text lines and their associated transcriptions will be displayed here.
          </div>
          <div v-else class="message-body">
            Recognition results will be displayed here.
          </div>
        </div>
      </template>
      <template v-else-if="loading">
        <div class="message is-info">
          <div class="message-body">
            <div class="is-flex is-justify-content-center">
              <div class="loader is-size-4 mb-3 mt-1"></div>
            </div>
            Document processing in progress
          </div>
        </div>
      </template>
      <div v-if="results && transcriptions.length">
        <h1 class="title is-4 has-text-info-dark">
          Document recognition results
          <span class="subtitle is-7 has-text-weight-light">(generated in {{ results.execution_time }}s)</span>
        </h1>
        <table class="table is-fullwidth is-hoverable">
          <tbody>
            <tr
              v-for="(result, index) in transcriptions"
              :key="index"
              :class="{ 'is-selected': index == selectedResult }"
              v-on:click="selectedResult = index"
            >
              <td class="is-clickable" :class="[languageCode === 'arb' ? 'has-text-right' : 'has-text-left']">{{ result.text }}</td>
            </tr>
          </tbody>
        </table>
        <button class="button is-small is-pulled-left" v-on:click="displayJSON = !displayJSON">{{ !displayJSON ? "Display raw JSON" : "Hide raw JSON" }}</button>
        <br>
        <json-viewer
          :value="results"
          :expand-depth="3"
          :copyable="{copyText: 'Copy JSON', copiedText: 'JSON copied', timeout: 2000}"
          boxed
          class="mt-4 has-text-left"
          v-if="displayJSON">
        </json-viewer>
      </div>
      <div v-else-if="results && layoutEntities.length">
        <h1 class="title is-4 has-text-info-dark">
          Form detection results
          <span class="subtitle is-7 has-text-weight-light">(generated in {{ results.execution_time }}s)</span>
        </h1>
        <table class="table is-fullwidth is-striped layout-table">
          <thead>
            <tr>
              <th>Key</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in layoutEntities"
              :key="index"
            >
              <td>{{ item.key }}</td>
              <td>{{ item.value }}</td>
            </tr>
          </tbody>
        </table>
        <button class="button is-small is-pulled-left" v-on:click="displayJSON = !displayJSON">{{ !displayJSON ? "Display raw JSON" : "Hide raw JSON" }}</button>
        <br>
        <json-viewer
          :value="results"
          :expand-depth="3"
          :copyable="{copyText: 'Copy JSON', copiedText: 'JSON copied', timeout: 2000}"
          boxed
          class="mt-4 has-text-left"
          v-if="displayJSON">
        </json-viewer>
      </div>
      <div v-else-if="results && layoutTables.length">
        <h1 class="title is-4 has-text-info-dark">
          Table detection results
          <span class="subtitle is-7 has-text-weight-light">(generated in {{ results.execution_time }}s)</span>
        </h1>
        <div v-for="(result, tableIndex) in layoutTables" :key="tableIndex">
          <h2 class="subtitle">Table n°{{ tableIndex+1 }}</h2>
          <table class="table is-fullwidth is-hoverable is-bordered layout-table mb-5">
            <tbody>
              <tr
                v-for="(row, rowIndex) in buildTable(result.cells)"
                :key="`${tableIndex}-${rowIndex}`"
              >
                <td
                  v-for="(cell, columnIndex) in row"
                  :key="`${tableIndex}-${rowIndex}-${columnIndex}`"
                  :class="{ 'is-clickable': cell, 'is-selected': cell && indexOf(polygons, cell.polygon) == selectedResult }"
                  v-on:click="cell ? selectedResult = indexOf(polygons, cell.polygon) : () => {}"
                >{{ cell ? cell.text : '' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <button class="button is-small is-pulled-left" v-on:click="displayJSON = !displayJSON">{{ !displayJSON ? "Display raw JSON" : "Hide raw JSON" }}</button>
        <br>
        <json-viewer
          :value="results"
          :expand-depth="3"
          :copyable="{copyText: 'Copy JSON', copiedText: 'JSON copied', timeout: 2000}"
          boxed
          class="mt-4 has-text-left"
          v-if="displayJSON">
        </json-viewer>
      </div>
      <div v-else-if="results && TRANSCRIPTION_LANGUAGE_CODES.includes(languageCode)" class="notification is-warning is-light">
        No line found
      </div>
      <div v-else-if="results" class="notification is-warning is-light">
        No data found
      </div>
    </div>
  </div>
</template>

<script>
import JsonViewer from 'vue-json-viewer'
import PictureInput from 'vue-picture-input'
import TermsOfService from './TermsOfService.vue'
import { saveAs } from 'file-saver'
import { indexOf, range } from 'lodash'
import { API_URL, LANGUAGES, ATR_API_URL, ACCOUNTS_API_URL, PRODUCT_MOCR_SLUG, PRODUCT_UFCN_PYLAIA_SLUG   } from '../main'
import { writeStore, readStore} from '../storage'

export default {
  data: () => ({
    TRANSCRIPTION_LANGUAGE_CODES: ["atr", "fra", "eng", "arb"],
    OCELUS_MOCR_LANGUAGES: ["atr", "gfr", "gtr-json", "gtr-xlsx"],
    OCELUS_UFCN_PYLAIA_LANGUAGES: ["fra", "eng", "arb"],
    languageCode: "",
    loading: false,
    processing: false,
    image: "",
    licenseRequested: false,
    email: "",
    results: "",
    selectedResult: "",
    warning: null,
    error: null,
    svgViewBox: "",
    displayJSON: false,
    indexOf
  }),
  components: {
    JsonViewer,
    PictureInput,
    TermsOfService
  },
  mounted () {
    if (this.enableATR) {
      this.languageCode = "atr"
      this.email = readStore(PRODUCT_MOCR_SLUG, "email");
    } else {
      this.languageCode = this.languages[0]
      this.email = readStore(PRODUCT_UFCN_PYLAIA_SLUG, "email");
    }
  },
  methods: {
    polygonPoints (points) {
      let output = ""
      points.forEach(point => {
        output += point[0] + "," + point[1] + " "
      })
      return output.trim()
    },
    buildTable (cells) {
      /**
       * Group cells by row to facilitate iteration and add empty cells
       * if they are missing to keep the correct display of the table.
       */
      const table = []
      const maxRow = Math.max(...cells.map(cell => cell.row))
      const maxColumn = Math.max(...cells.map(cell => cell.column))
      range(maxRow+1).forEach(rowIndex => {
        table[rowIndex] = []
        range(maxColumn+1).forEach(columnIndex => {
          table[rowIndex][columnIndex] = cells.find(cell => cell.row == rowIndex && cell.column == columnIndex)
        })
      })
      return table
    },
    resetData () {
      this.results = ""
      this.selectedResult = ""
      this.warning = null
      this.error = null
      this.displayJSON = false
    },
    retry () {
      this.resetData()
      this.$refs.pictureInput.removeImage()
      this.processing = false
    },
    setImage (imageDataURI) {
      this.image = imageDataURI
    },
    fileFromDataURI (dataURI) {
      let URISplit = dataURI.split(',')
      const mime = URISplit[0].split(":")[1].split(";")[0]
      const ext = mime.split("/")[1]
      const imageBuffer = Buffer.from(URISplit[1], "base64")
      return new File([imageBuffer], "image." + ext, { "type": mime })
    },
    buildAPIRequest (imageDataURI) {
      let formData = new FormData()
      formData.append("image", this.fileFromDataURI(imageDataURI))

      // Add license key for the requested engine
      const headers = new Headers()
      headers.append("API-Key", this.licenseKey)

      let baseURL
      if (this.languageCode == "atr") {
        /* 'atr' for Automatic Text Recognition */
        console.log("Sending image to the ATR Ocelus API")
        baseURL = ATR_API_URL + "/transcribe/"
      } else if (this.languageCode == "gfr") {
        /* 'gfr' for Generic Form Recognizer */
        console.log("Sending image to the GFR Ocelus API")
        baseURL = ATR_API_URL + "/entities/"
      } else if (this.languageCode.startsWith("gtr-")) {
        /* 'gtr' for Generic Table Recognizer */
        console.log("Sending image to the GTR Ocelus API")
        baseURL = ATR_API_URL + "/tables/"
        formData.append("output_mode", this.languageCode.replace("gtr-", ""))
      } else {
        console.log("Sending image to Ocelus API")
        baseURL = API_URL + "/transcribe/" + this.languageCode
      }

      return new Request(
        baseURL,
        {
          method: "POST",
          mode: "cors",
          headers,
          body: formData
        }
      )
    },
    async requestLicense() {
      // Request towards Accounts to trigger trial license workflow
      let accounts_url = null;
      if (this.isOcelusMocr) {
        accounts_url = `${ACCOUNTS_API_URL}/product/${PRODUCT_MOCR_SLUG}/email/`
      }else if (this.isOcelusUFCNPylaia) {
        accounts_url = `${ACCOUNTS_API_URL}/product/${PRODUCT_UFCN_PYLAIA_SLUG}/email/`
      }else{
        this.warning = {detail: "Please register your email to get a license key for the selected engine"}
        return
      }

      // This endpoint creates a Contact and will provide a license key through email
      // to the requesting user
      let formData = new FormData()
      formData.append("email", this.email)
      let request = new Request(
        accounts_url,
        {
          method: "POST",
          mode: "cors",
          body: formData,
        }
      )
       // The component remains in "loading" mode because the user has to use the link provided by email from there.
      this.loading = true;
      try {
        const res = await fetch(request)
        if (res.status == 201) {
          this.licenseRequested = true;

          // Store email for that product so it can be displayed on future usages
          if (this.isOcelusMocr) {
            writeStore(PRODUCT_MOCR_SLUG, "email", this.email);
          }else if (this.isOcelusUFCNPylaia) {
            writeStore(PRODUCT_UFCN_PYLAIA_SLUG, "email", this.email);
          }

        } else {
          throw await res.json()
        }
      } catch (err) {
        this.error = err
        this.loading = false
      }
    },
    async generateResults () {

      // Need a license key before reaching any ocelus service
      if (!this.licenseKey) {
        await this.requestLicense();
        return;
      }

      if (!this.image) {
        this.warning = {detail: "Please upload an image"}
        return
      }
      this.processing = true
      this.resetData()
      this.loading = true

      const request = this.buildAPIRequest(this.image)

      try {
        const res = await fetch(request)
        if (res.status == 200) {
          // Download the XLSX file
          if (this.languageCode === "gtr-xlsx") {
            const blob = await res.blob()
            saveAs(blob, "tables.xlsx")
          } else {
            this.results = await res.json()
            /**
             * Storing the language code at this point allows us to know how the results
             * were produced, and so how they should be displayed (as transcription
             * results or as form detection results). If this display depended on the
             * current language code, then a user switching between recognition engines
             * after results have been generated would change (and break) the display.
             */
            this.results['request_language_code'] = this.languageCode
          }
        } else if (res.status == 401) {
          this.warning = await res.json()
          this.processing = false
        } else {
          throw await res.json()
        }
      } catch (err) {
        this.error = err
        this.processing = false
      } finally {
        this.loading = false
      }
    },
    async loadImage (src) {
      return new Promise((resolve, reject) => {
        let img = new Image()
        img.onload = () => resolve(img)
        img.onerror = reject
        img.src = src
      })
    },
    resetLicense () {
      if (this.isOcelusMocr) {
        writeStore(PRODUCT_MOCR_SLUG, "key", "");
        writeStore(PRODUCT_MOCR_SLUG, "email", "");
      }  else if (this.isOcelusUFCNPylaia) {
        writeStore(PRODUCT_UFCN_PYLAIA_SLUG, "key", "");
        writeStore(PRODUCT_UFCN_PYLAIA_SLUG, "email", "");
      }

      // Hack to force `licenseKey` computed to refresh because it won't detect the change in the local storage automatically
      const languageCode = this.languageCode
      this.languageCode = ""
      this.languageCode = languageCode
    }
  },
  computed: {
    enableATR () {
      return Boolean(ATR_API_URL)
    },
    languages () {
      return LANGUAGES.split(',')
    },
    polygons () {
      if (this.transcriptions.length) return this.transcriptions.map(transcription => transcription.polygon)
      else if (this.layoutTables.length) return this.layoutTables.map(table => table.cells.map(cell => cell.polygon)).flat()
      else return []
    },
    transcriptions () {
      if (this.results?.results && this.TRANSCRIPTION_LANGUAGE_CODES.includes(this.results.request_language_code)) return this.results.results
      else return []
    },
    layoutEntities () {
      if (this.results?.results && this.results.request_language_code == 'gfr') return this.results.results
      else return []
    },
    layoutTables () {
      if (this.results?.results && this.results.request_language_code == 'gtr-json') return this.results.results
      else return []
    },
    warningMessage () {
      if (this.warning === null) return ""
      return this.warning?.detail || JSON.stringify(this.warning)
    },
    errorMessage () {
      if (this.error === null) return ""
      return this.error?.detail || JSON.stringify(this.error)
    },
    hasResults () {
      return (this.transcriptions.length || this.layoutEntities.length || this.layoutTables.length)
    },
    licenseKey () {
      // Current license key depends on select language (engine)
      if (this.isOcelusMocr) {
        return readStore(PRODUCT_MOCR_SLUG, "key")
      }  else if (this.isOcelusUFCNPylaia) {
        return readStore(PRODUCT_UFCN_PYLAIA_SLUG, "key")
      } else {
        return null;
      }
    },
    isOcelusMocr () {
      return this.languageCode && this.OCELUS_MOCR_LANGUAGES.includes(this.languageCode)
    },
    isOcelusUFCNPylaia() {
      return this.languageCode && this.OCELUS_UFCN_PYLAIA_LANGUAGES.includes(this.languageCode)
    }
  },
  watch: {
    image: async function (newValue, oldValue) {
      if (newValue == oldValue) return
      const img = await this.loadImage(newValue)
      this.svgViewBox = "0 0 " + img.width + " " + img.height
    }
  }
}
</script>

<style scoped>
.sticky-image{
  position: sticky;
  display: inline-block;
  top: 80px;
}
/* Prevent table rows to collapse in height when they're empty */
td:empty::after{
  content: "\00a0";
}
.table:not(.is-bordered) td {
  border: none;
}
.table:not(.is-bordered) th {
  border: none;
}
.layout-table {
  text-align: left;
}
</style>
