<template>
  <div class="container">
    <div class="notification is-success is-light" v-if="success">
      {{ successMessage }}
    </div>
    <div class="notification is-danger is-light" v-else-if="error">
      {{ errorMessage }}
    </div>
    <router-link class="button is-info" :class="{ disabled: loading }" :to="{ name: 'home' }">Back to homepage</router-link>
  </div>
</template>

<script>
import { ATR_API_URL } from '../main'

export default {
  data: () => ({
    loading: false,
    success: null,
    error: null,
  }),
  mounted () {
    this.validateEmail()
  },
  computed: {
    successMessage () {
      if (this.success === null) return ""
      return this.success?.message || JSON.stringify(this.success)
    },
    errorMessage () {
      if (this.error === null) return ""
      return this.error?.detail || JSON.stringify(this.error)
    },
  },
  methods: {
    async validateEmail () {
      const email = this.$route.query.email
      const token = this.$route.query.token
      if (!email || !token) {
        this.error = {detail: "The validation link you followed is erroneous"}
        return
      }

      this.loading = true

      const params = new URLSearchParams({
        email,
        token,
      })
      const request = new Request(
        ATR_API_URL + "/validate-email/?" + params,
        {
          method: "POST",
          mode: "cors",
        }
      )

      try {
        const res = await fetch(request)
        if (res.status == 200) {
          this.success = await res.json()
        } else {
          throw await res.json()
        }
      } catch (err) {
        this.error = err
      } finally {
        this.loading = false
      }
    },
  }
}
</script>

<style scoped>
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
