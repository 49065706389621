import './main.scss'
import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import App from './App.vue'

import ImageUploader from './components/ImageUploader.vue'
import ValidateEmail from './components/ValidateEmail.vue'

// Setup connection towards Doc-UFCN + Pylaia Ocelus
export const API_URL = process.env.VUE_APP_API_URL

// Setup connection towards Accounts service
export const ACCOUNTS_API_URL = process.env.VUE_APP_ACCOUNTS_API_URL
export const PRODUCT_MOCR_SLUG = process.env.VUE_APP_PRODUCT_MOCR_SLUG
export const PRODUCT_UFCN_PYLAIA_SLUG = process.env.VUE_APP_PRODUCT_UFCN_PYLAIA_SLUG

// Control if the teklia branding is displayed or not
export const BRAND = process.env.VUE_APP_BRAND

// Control which languages are available on this instance
export const LANGUAGES = process.env.VUE_APP_LANGUAGES

// Optional ATR Ocelus configuration. If url is set, this OCR will be selected by default
export const ATR_API_URL = process.env.VUE_APP_ATR_API_URL

Vue.use(Vuex)
Vue.config.productionTip = false
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  routes: [
    { path: '/', name: 'home', component: ImageUploader },
    { path: '/validate-email', name: 'validate-email', component: ValidateEmail },
  ],
})

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
