<template>
  <div id="app">
    <div class="notification is-secondary is-light has-text-centered" v-if="atr_docs_url" >
      Ocelus API <a :href="atr_docs_url" target="_blank">documentation</a>
    </div>
    <a href="https://teklia.com"><img v-if="brand == 'teklia'" width="300px" alt="Teklia logo" src="https://assets.teklia.com/ocelus-demo/logo.png"></a>
    <h1 class="title is-1 has-text-grey-dark is-family-sans-serif"> Ocelus Demo</h1>
    <h2 class="title is-2 has-text-grey-dark is-family-sans-serif">Try our Automatic Text  Recognition (ATR) engines</h2>
    <p  class="is-size-4 has-text-grey-dark is-family-sans-serif">These generic engines are optimized for <strong>simple modern documents</strong>, printed or handwritten.</p>
    <p  class="is-size-4 has-text-grey-dark is-family-sans-serif"> For historical or specific documents, <a href='https://teklia.com/company/contact/'>contact us</a> for high performance customized models.</p>
    <hr>
    <router-view></router-view>
  </div>
</template>

<script>
import { BRAND, ATR_API_URL } from './main'

export default {
  name: 'App',
  data: () => ({
    brand: BRAND,
  }),
  computed: {
    atr_docs_url: () => {
      if ( ! ATR_API_URL ) {
        return null;
      }

      const url = new URL(ATR_API_URL);
      return url.origin;
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
