// Name of the Local storage for license keys
const LOCAL_STORAGE_LICENSE_PREFIX = "ocelus-demo-license"

// Read in local storage
export function readStore(product, key) {
  const fullKey = `${LOCAL_STORAGE_LICENSE_PREFIX}:${product}:${key}`
  return localStorage.getItem(fullKey)
}

// Write in local storage
export function writeStore(product, key, value) {
  const fullKey = `${LOCAL_STORAGE_LICENSE_PREFIX}:${product}:${key}`
  localStorage.setItem(fullKey, value)
}
