<template>
  <div class="container">
    <div class="notification is-danger is-light" v-if="error">
      {{ error }}
    </div>
    <router-link class="button is-info" :to="{ name: 'home' }">Back to homepage</router-link>
  </div>
</template>

<script>
import {PRODUCT_MOCR_SLUG , PRODUCT_UFCN_PYLAIA_SLUG } from "../main";
import {writeStore} from "../storage";

export default {
  data: () => ({
    error: null,
  }),
  mounted () {
    this.validateEmail()
  },
  methods: {
    async validateEmail () {
      const product = this.$route.query.product
      const license_key = this.$route.query.license_key
      if (!product || !license_key || ![PRODUCT_MOCR_SLUG , PRODUCT_UFCN_PYLAIA_SLUG].includes(product)) {
        this.error = "The validation link you followed is erroneous"
        return
      }

      // Store license key in local storage
      writeStore(product, "key", license_key);

      // Redirect user to homepage
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style scoped>
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
